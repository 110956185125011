import {useSelector} from "react-redux";
import {
  getErschieneneTonaufnahmeGetter,
  getGEMAGVL4AusstrahlungGetter,
  getGEMAGVLXMLAusstrahlungGetter, getGEMAGVLXMLLieferungGetter,
  getMusikProduktionIdGetter,
  getStationGetter,
  getTodoTaskGetter
} from "src/features/entity";
import { darken, lighten } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Alert, AlertTitle, Skeleton } from '@mui/material';
import React, {useState} from "react";
import {
  AccessTime,
  Add,
  Album,
  Assignment,
  Build, Check, Clear,
  Delete,
  ExpandMore,
  FilterCenterFocus,
  HelpOutline, ImportExport,
  LabelImportant,
  MoreHoriz,
  QueueMusic, Search,
  VolumeUp, DateRange
} from "@mui/icons-material";
import ProgrammeChip from "src/components/entities/programme/ProgrammeChip";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Collapse,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography
} from "@mui/material";
import {useEntityApi, useEntityDeleter, useEntityObserver, useOrgMusicWork} from "src/features/entity/entity-hooks";
import {getSelectedOrganization, getSelectedOrganizationId} from "src/features/dashboard";
import LieferantIDFormDialog from "src/components/entities/user/LieferantIDFormDialog";
import PlaylistItem from "src/components/entities/gemagvlxml/components/PlaylistItem";
import {MusicProductionIDForm} from "src/components/entities/musicwork/MusicProductionIDForm";
import {MusicPersonForm} from "src/components/entities/musicwork/MusicPersonForm";
import {MusicWorkForm} from "src/components/entities/musicwork/MusicWorkForm";
import {MusicNutzungForm} from "src/components/entities/musicwork/MusicNutzungForm";
import {ErschieneneTonaufnahmeForm} from "src/components/entities/musicwork/ErschieneneTonaufnahmeForm";
import {ProgressButton, ProgressIconButton} from "src/packages/react-hook-form-mui-form-fields/submit-button-view";
import HrefButton from "src/packages/gatsby-mui-helpers/HrefButton";
import GEMAGVL4ImportSettingsFormDialog from "src/components/entities/user/GEMAGVL4ImportSettingsFormDialog";
import {TODO_TASK} from "src/api/api-schemas";
import CircularProgress from "@mui/material/CircularProgress";
import {MUSIK_PRODUKTION_ID_TYP_KNZ} from "src/features/dashboard/dashboard-validation";
import NaturalDateRange from "src/components/entities/gemagvlxml/components/NaturalDateRange";
import dateFormat from "dateformat";
import {MusicWorkGVLProductComparisons} from "src/components/entities/musicwork/components/MusicWorkGVLProductComparison";
import {useHasPermissions} from "src/features/dashboard/dashboard-hooks";
import Playlist from "src/components/entities/gemagvlxml/components/Playlist";
import JingleMusikHerkunftSettingsFormDialog from "src/components/entities/user/JingleMusikHerkunftSettingsFormDialog";
import CollectiveReportCoveredMonthsFormDialog
  from "src/components/entities/gemagvlxml/CollectiveReportCoveredMonthsFormDialog";

const useStyles = makeStyles((theme) => {
  const getColor = theme.palette.mode === 'light' ? darken : lighten;
  const getBackgroundColor = theme.palette.mode === 'light' ? lighten : darken;

  return {
    todoTaskAccordion: ({severity}) => ({
      //background: theme.palette?.[severity]?.light,
      //height: 'auto',
      //margin: 0,
      //marginBottom: theme.spacing(1),
      color: getColor(theme.palette?.[severity]?.light, 0.6),
      backgroundColor: getBackgroundColor(theme.palette?.[severity]?.light, 0.9),
      // '& $icon': {
      //   color: theme.palette?.[severity]?.main,
      // },
      '& .MuiAccordionSummary-root': {
        paddingLeft: 0,
      },
      '& .Mui-expanded': {
        '& > .MuiAlert-root': {
          paddingTop: 0,
          paddingBottom: 0,
        },
        marginBottom: theme.spacing(0.5),
      },
      '& .MuiAccordionDetails-root': {
        paddingTop: 0,
      },
      '& .MuiAlert-action': {
        alignItems: 'flex-start',
      },
    }),
    todoTaskWrapper: {
      position: 'relative',
      '& .MuiAlert-icon': {
        color: ({severity}) => theme.palette?.[severity]?.main,
      }
    },
    suggestionsWrapper: {
      '& .MuiAlert-icon': {
        color: '#999',
      },
      '& > .MuiAlert-icon': {
        color: '#fff',
      },
    },
    deleteButtonWrapper: {
      // margin: theme.spacing(1),
      position: 'absolute',
      top: theme.spacing(0),
      right: theme.spacing(0),
      color: theme.palette.warning.main,
    },
    spacer: {
      flex: 1,
    },
  };
});

const QUICKFIX_ACTION_TYPE_ICONS = {
  decline_relation: <Clear/>,
  confirm_relation: <Check/>,
};

const GenericTodoTask = React.memo(function (
  {
    id,
    message,
    children,
    gemagvlxmlLieferungId,
    todo_type,
    hidePlaylistButton,
    hideMusicWorksButton,
    deleteTask,
    isDeletingTask,
    actions,
    customButtons,
    is_ausstrahlung_related: isAusstrahlungRelated,
    is_music_work_related: isMusicRelated,
    affected_music_works_count: affectedMusicWorksCount,
    affected_ausstrahlungen_count: affectedAusstrahlungenCount,
    is_processing: isProcessing,
    suggested_quickfixes: suggestedQuickfixes,
    defaultExpanded = true,
    permissions,
    ...props
  }
) {
  const showMusicWorkButton = isMusicRelated && !hideMusicWorksButton && gemagvlxmlLieferungId;
  const showPlaylistButton = (isAusstrahlungRelated || (isMusicRelated && hideMusicWorksButton)) && !hidePlaylistButton && gemagvlxmlLieferungId && !showMusicWorkButton;

  const canWrite = permissions?.write;

  const showIgnoreButton = (message?.severity === 'warning' || message?.severity === 'info') && deleteTask && canWrite;
  let showSimpleIgnoreButton = showIgnoreButton;
  let multipleItemsAffected = false;

  if (deleteTask && gemagvlxmlLieferungId) {
    const originalDeleteTask = deleteTask;
    deleteTask = () => {
      confirm("Aufgabe inklusive Unteraufgaben löschen?") && originalDeleteTask();
    };
    showSimpleIgnoreButton = false;
    multipleItemsAffected = true;
  }

  const showMenu = showIgnoreButton && !showSimpleIgnoreButton;

  const [expanded, setExpanded] = useState(defaultExpanded);

  const classes = useStyles({severity: message?.severity || 'info'});

  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const hasSuggestions = message?.suggestions;
  const [showSuggestions, setShowSuggestions] = React.useState(null);

  const entityApi = useEntityApi(TODO_TASK);
  const organization = useSelector(getSelectedOrganizationId);
  const performQuickfix = async ({id, ...data}) => {
    await entityApi.post(
      `/api/sendemeldung/organizations/${organization}/todo_tasks/${id}/quickfix/`,
      data,
    );
  };

  const showNecessityAlert = gemagvlxmlLieferungId && message?.severity === 'error';

  return <>
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)} TransitionProps={{unmountOnExit: true}}
               className={classes.todoTaskAccordion}>
      <AccordionSummary
        expandIcon={<ExpandMore/>}
        aria-controls={`${id}-content`}
        id={`${id}-header`}
      >
        <Alert severity={message?.severity === 'magic' ? 'info' : message?.severity || 'info'} icon={<Assignment/>} className={classes.todoTaskWrapper}>
          <AlertTitle>
            {message?.title || <Skeleton variant="text" width={200}/>}
          </AlertTitle>
        </Alert>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          {children ? (
            <>
              <Typography gutterBottom>{message?.text}</Typography>
              {children}
            </>
          ) : (
            <Typography gutterBottom>{message?.text}</Typography>
          )}

          {message?.suggestions ? (
            <Collapse in={!!showSuggestions}>
              <Box mt={2}>
                <Alert
                  variant="filled"
                  severity="info"
                  icon={<HelpOutline/>}
                  onClose={() => setShowSuggestions(false)}
                  className={classes.suggestionsWrapper}
                >
                  {message?.suggestions?.length === 1 ? (
                    <>
                      <AlertTitle>Hilfestellung zu dieser Aufgabe</AlertTitle>
                      <Typography>{message?.suggestions[0]}</Typography>
                    </>
                  ) : (
                    <>
                      <AlertTitle>Hilfestellungen zu dieser Aufgabe</AlertTitle>
                      {message?.suggestions?.map((suggestion, i) => (
                        <Box key={i} mt={2} mb={2}>
                          <Alert variant="standard" severity="info" icon={<LabelImportant/>}>
                            <Typography>{suggestion}</Typography>
                          </Alert>
                        </Box>
                      ))}
                    </>
                  )}
                </Alert>
              </Box>
            </Collapse>
          ) : null}

          {isProcessing ? (
            <Box mt={2}>
              <Alert variant="filled" severity="info" icon={<CircularProgress size="1rem" color="inherit"/>}>
                <AlertTitle>
                  Bitte haben Sie einen Augenblick Geduld
                </AlertTitle>
                Wir sind gerade dabei, den von Ihnen ausgewählten Lösungsvorschlag umzusetzen.
                Dies kann einige Minuten dauern.
              </Alert>
            </Box>
          ) : null}
        </div>
      </AccordionDetails>
      {(showMenu || showIgnoreButton || showPlaylistButton || showMusicWorkButton || actions || (hasSuggestions && !showSuggestions) || showNecessityAlert) ? (<>
          <Divider/>
          <AccordionActions>
            {showMenu && !isDeletingTask ? (
              <>
                <Tooltip title="weitere Aktionen">
                  <IconButton
                    aria-label="more actions"
                    aria-controls={`${id}-more-menu`}
                    aria-haspopup="true"
                    onClick={openMenu}
                    size="large">
                    <MoreHoriz/>
                  </IconButton>
                </Tooltip>
                <Menu
                  id={`${id}-more-menu`}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={menuOpen}
                  onClose={closeMenu}
                >
                  {showIgnoreButton ? (
                    <MenuItem onClick={deleteTask}>
                      <ListItemIcon>
                        <Delete fontSize="small"/>
                      </ListItemIcon>
                      <ListItemText>
                        {multipleItemsAffected ? "Hinweis für alle betroffenen Elemente löschen" : "Hinweis löschen"}
                      </ListItemText>
                    </MenuItem>
                  ) : null}
                </Menu>
              </>
            ) : showIgnoreButton ? (
              <Tooltip
                title={multipleItemsAffected ? "Hinweis für alle betroffenen Elemente löschen" : "Hinweis löschen"}>
            <span>
              <ProgressIconButton
                onClick={deleteTask}
                disabled={isDeletingTask}
                inProgress={isDeletingTask}
                color="default"
              >
                <Delete/>
              </ProgressIconButton>
            </span>
              </Tooltip>
            ) : null}

            {hasSuggestions && !showSuggestions ? (
              <Tooltip title="Hilfestellungen anzeigen">
                <IconButton onClick={() => setShowSuggestions(true)} size="large">
                  <HelpOutline/>
                </IconButton>
              </Tooltip>
            ) : null}

            <div style={{flex: 1}}/>

            {showNecessityAlert ? (
                <>
                  <Tooltip
                    title="Diese Aufgabe muss zwingend bearbeitet werden, um eine Generierung von XML-Sendemeldungen technisch zu ermöglichen.">
                    <Chip
                      color="primary"
                      label="Bearbeitung zwingend erforderlich"
                    />
                  </Tooltip>
                  <div style={{flex: 1}}/>
                </>
            ) : null}

            {isProcessing ? (
              <>
                <Tooltip title="Die Verarbeitung kann einige Minuten dauern.">
                  <div>
                    <ProgressButton
                      inProgress
                    >
                      wird verarbeitet...
                    </ProgressButton>
                  </div>
                </Tooltip>
              </>
            ) : (
              <>
                {suggestedQuickfixes?.map(({type, caption, help_text, confirmation_text, data}, i) => {
                  let button = (
                    <Button
                      key={type || i}
                      startIcon={QUICKFIX_ACTION_TYPE_ICONS[type] || <Build/>}
                      label={caption}
                      color="primary"
                      disabled={!canWrite}
                      onClick={(event) => {
                        event.stopPropagation();
                        if (!confirmation_text || confirm(confirmation_text)) {
                          performQuickfix({id, type, data});
                        }
                      }}
                    >
                      {caption}
                    </Button>
                  );

                  if (!canWrite) {
                    return null;
                  }

                  return help_text ? (
                    <Tooltip key={type} title={help_text}>
                      {button}
                    </Tooltip>
                  ) : button;
                })}
                {actions}
              </>
            )}

            {customButtons}

            {showPlaylistButton ? (
              <HrefButton
                startIcon={<QueueMusic/>}
                color="primary"
                href={`/dashboard/reports/${gemagvlxmlLieferungId}/entries/?todoTasks=true&todoTaskId=${id}`}
              >
                {affectedAusstrahlungenCount === 1 ? (
                  <>einzige betroffene Ausstrahlung anzeigen</>
                ) : affectedAusstrahlungenCount ? (
                  <>alle {affectedAusstrahlungenCount} betroffenen Ausstrahlungen anzeigen</>
                ) : (
                  <>alle betroffenen Ausstrahlungen anzeigen</>
                )}
              </HrefButton>
            ) : null}

            {showMusicWorkButton ? (
              <HrefButton
                startIcon={<Album/>}
                color="primary"
                href={`/dashboard/reports/${gemagvlxmlLieferungId}/music/?todoTaskId=${id}`}
              >
                {affectedMusicWorksCount === 1 ? (
                  <>einzige betroffene Musikproduktion anzeigen</>
                ) : affectedMusicWorksCount ? (
                  <>alle {affectedMusicWorksCount} betroffenen Musikproduktionen anzeigen</>
                ) : (
                  <>alle betroffenen Musikproduktionen anzeigen</>
                )}
              </HrefButton>
            ) : null}

          </AccordionActions>
        </>
      ) : null}
    </Accordion>
  </>;
});

const NoLieferantIdTodoTask = React.memo(function (props) {
  const organization = useSelector(getSelectedOrganization);
  const [editLieferantID, setEditLieferantID] = useState(null);

  const hasPermission = useHasPermissions({perm_write_details: true});

  return (
    <GenericTodoTask
      {...props}
      actions={(!organization?.gemagvlxml_lieferant_id && hasPermission) ? (
        <>
          <Button
            startIcon={<Add/>}
            label="Lieferant-ID ergänzen"
            clickable
            color="primary"
            onClick={(event) => {
              event.stopPropagation();
              setEditLieferantID(organization);
            }}
          >
            Lieferant-ID ergänzen
          </Button>
        </>
      ) : null}
    >
      {editLieferantID ? (
        <LieferantIDFormDialog
          data={editLieferantID}
          onClose={() => setEditLieferantID(null)}
        />
      ) : null}
    </GenericTodoTask>
  );
});

const NoStationIdTodoTask = React.memo(function ({stationIds, ...props}) {
  const getStation = useSelector(getStationGetter);
  const stations = stationIds?.map(getStation);

  return (
    <GenericTodoTask {...props}>
      {stations?.filter(({gemagvl_sender_prg_id}) => !gemagvl_sender_prg_id).map(({id: stationId}, i) => (
        <ProgrammeChip key={stationId || i} id={stationId}/>
      ))}
    </GenericTodoTask>
  );
});

const GEMAGVL4AusstrahlungRelatedTodoTask = React.memo(function ({gemagvlxmlausstrahlungId, ...props}) {
  const getGEMAGVLXMLAusstrahlung = useSelector(getGEMAGVLXMLAusstrahlungGetter);
  const {lineno, gemagvl4_ausstrahlung: gemagvl4ausstrahlungId} = getGEMAGVLXMLAusstrahlung(gemagvlxmlausstrahlungId);

  const getGEMAGVL4Ausstrahlung = useSelector(getGEMAGVL4AusstrahlungGetter);
  const {original_line} = getGEMAGVL4Ausstrahlung(gemagvl4ausstrahlungId);

  useEntityObserver({type: 'gemagvl4_ausstrahlung', id: gemagvl4ausstrahlungId});

  return (
    <GenericTodoTask {...props}>
      {gemagvl4ausstrahlungId ? (
        <>
          <Typography component="h5">
            Betroffene Zeile (#{lineno})
          </Typography>
          <pre style={{whiteSpace: 'pre-wrap'}}>
            {original_line}
          </pre>
        </>
      ) : null}
    </GenericTodoTask>
  );
});

const OverlappingTimesTodoTask = React.memo(function ({metadata, gemagvlxmlausstrahlungId, ...props}) {
  const {conflicting_gemagvlxml_ausstrahlung_id: ausstrahlungId} = metadata || {};

  const getGEMAGVLXMLAusstrahlung = useSelector(getGEMAGVLXMLAusstrahlungGetter);
  const {gemagvlxml_lieferung} = getGEMAGVLXMLAusstrahlung(gemagvlxmlausstrahlungId);

  const noEntriesContent = (
    <>
      <PlaylistItem
        id={ausstrahlungId}
        statusText="überlappende Ausstrahlung"
        statusIcon={<AccessTime color="primary" fontSize="large"/>}
      />
      {gemagvlxmlausstrahlungId ? (
        <PlaylistItem
          id={gemagvlxmlausstrahlungId}
          previousId={ausstrahlungId}
          expanded={false}
          expansible={false}
          statusText="betrachtete Ausstrahlung"
          statusIcon={<FilterCenterFocus fontSize="large"/>}
        />
      ) : null}
    </>
  );

  return (
    <GenericTodoTask {...props}>
      {gemagvlxmlausstrahlungId ? (
        <Box mt={2}>
          <Typography gutterBottom>
            Erkannte Überlappung(en):
          </Typography>

          <Playlist
            listingId={`overlaps[${gemagvlxmlausstrahlungId}]`}
            gemagvlxml_lieferung={gemagvlxml_lieferung}
            loadingContent={(
              <Alert variant="filled" severity="info" icon={<CircularProgress size="1rem" color="inherit"/>}>
                Überlappende Ausstrahlungen werden geladen...
              </Alert>
            )}
            disableEdit
            meta={{
              intersects_with: gemagvlxmlausstrahlungId,
            }}
            isEmbedded
            filterParamName={`ausstrahlung${gemagvlxmlausstrahlungId}filter`}
            listingProps={{
              orderingParamName: `ausstrahlung${gemagvlxmlausstrahlungId}ordering`,
              pageSizeParamName: `ausstrahlung${gemagvlxmlausstrahlungId}pageSize`,
              pageParamName: `ausstrahlung${gemagvlxmlausstrahlungId}page`,
              defaultPageSize: 20,
            }}
            noEntriesContent={noEntriesContent}
            getPlaylistItemProps={(id) => (
              id === gemagvlxmlausstrahlungId ? (
                {
                  statusText: "betrachtete Ausstrahlung",
                  statusIcon: (<FilterCenterFocus fontSize="large"/>),
                  expanded: false,
                  expansible: false,
                  disableEdit: true,
                }
              ) : (
                {
                  statusText: "überlappende Ausstrahlung",
                  statusIcon: (<AccessTime color="primary" fontSize="large"/>),
                  disableEdit: true,
                }
              )
            )}
          >
            {noEntriesContent}
          </Playlist>
        </Box>
      ) : null}
    </GenericTodoTask>
  );
});

const StationRelatedTodoTask = React.memo(function ({metadata, ...props}) {
  const {stations: stationIds} = metadata || {};

  return (
    <GenericTodoTask {...props}>
      {stationIds ? (
        <Box mt={2}>
          <Typography gutterBottom>
            Betroffene Programme:
            {' '}
            {stationIds?.map((stationId, i) => (
              <ProgrammeChip key={stationId || i} id={stationId} showEdit/>
            ))}
          </Typography>
        </Box>
      ) : null}
    </GenericTodoTask>
  );
});

const ProduktionIDTodoTask = React.memo(function ({metadata, ...props}) {
  const {musik_produktion_id: musikProduktionId} = metadata || {};

  const [editProduktionId, setEditProduktionId] = useState(null);

  const getMusikProduktionId = useSelector(getMusikProduktionIdGetter);
  const data = getMusikProduktionId(musikProduktionId);
  const {id_typ_knz} = data || {};
  const typ = MUSIK_PRODUKTION_ID_TYP_KNZ[id_typ_knz] || "Identifier";

  const hasPermission = useHasPermissions({perm_write_music: true});

  return (
    <GenericTodoTask
      {...props}
      actions={(data?.id && hasPermission) ? (
        <>
          <Button
            color="primary"
            onClick={() => setEditProduktionId(data)}
          >
            {typ} korrigieren
          </Button>
          {editProduktionId ? (
            <MusicProductionIDForm
              data={editProduktionId}
              onClose={() => setEditProduktionId(null)}
              autoFocusField='id_wert'
            />
          ) : null}
        </>
      ) : null}
    />
  );
});

const MissingProdIdTodoTask = React.memo(function ({metadata, ...props}) {
  const {org_music_work_id: orgMusicWorkId} = metadata || {};

  const [editProduktionId, setEditProduktionId] = useState(null);

  const {organization, ...data} = useOrgMusicWork({id: orgMusicWorkId, observe: false});

  const hasPermission = useHasPermissions({perm_write_music: true});

  return (
    <GenericTodoTask
      {...props}
      actions={(data?.id && hasPermission) ? (
        <>
          <Button
            startIcon={<Add/>}
            color="primary"
            onClick={() => setEditProduktionId({organization, org_music_work: orgMusicWorkId, id_typ_knz: 'KATALOG_NR'})}
          >
            Katalognummer
          </Button>
          <Button
            startIcon={<Add/>}
            color="primary"
            onClick={() => setEditProduktionId({organization, org_music_work: orgMusicWorkId, id_typ_knz: 'EAN_UPC'})}
          >
            EAN/UPC
          </Button>
          <Button
            startIcon={<Add/>}
            color="primary"
            onClick={() => setEditProduktionId({organization, org_music_work: orgMusicWorkId, id_typ_knz: 'ISRC'})}
          >
            ISRC ergänzen
          </Button>
          {editProduktionId ? (
            <MusicProductionIDForm
              data={editProduktionId}
              onClose={() => setEditProduktionId(null)}
              autoFocusField={({id_typ_knz, id_wert}) => !id_typ_knz ? 'id_typ_knz' : 'id_wert'}
            />
          ) : null}
        </>
      ) : null}
    />
  );
});

const MissingUrheberTodoTask = React.memo(function ({metadata, ...props}) {
  const {org_music_work_id: orgMusicWorkId} = metadata || {};

  const [editPerson, setEditPerson] = useState(null);

  const {organization, ...data} = useOrgMusicWork({id: orgMusicWorkId, observe: false});

  const hasPermission = useHasPermissions({perm_write_music: true});

  return (
    <GenericTodoTask
      {...props}
      actions={(data?.id && hasPermission) ? (
        <>
          <Button
            startIcon={<Add/>}
            color="primary"
            onClick={() => setEditPerson({org_music_work: orgMusicWorkId, organization, musik_person_rolle_knz: 'K'})}
          >
            Urheber ergänzen
          </Button>
          {editPerson ? (
            <MusicPersonForm
              data={editPerson}
              onClose={() => setEditPerson(null)}
              autoFocusField="name"
            />
          ) : null}
        </>
      ) : null}
    />
  );
});

const MissingInterpretTodoTask = React.memo(function ({metadata, ...props}) {
  const {org_music_work_id: orgMusicWorkId} = metadata || {};

  const [editPerson, setEditPerson] = useState(null);

  const {organization, ...data} = useOrgMusicWork({orgMusicWorkId, observe: false});

  const hasPermission = useHasPermissions({perm_write_music: true});

  return (
    <GenericTodoTask
      {...props}
      actions={(data?.id && hasPermission) ? (
        <>
          <Button
            startIcon={<Add/>}
            color="primary"
            onClick={() => setEditPerson({org_music_work: orgMusicWorkId, organization, musik_person_rolle_knz: 'INT'})}
          >
            Interpret ergänzen
          </Button>
          {editPerson ? (
            <MusicPersonForm
              data={editPerson}
              onClose={() => setEditPerson(null)}
              autoFocusField="name"
            />
          ) : null}
        </>
      ) : null}
    />
  );
});

const MissingTitleTodoTask = React.memo(function ({metadata, ...props}) {
  const {org_music_work_id: orgMusicWorkId} = metadata || {};

  const [editMusicWork, setEditMusicWork] = useState(null);

  const {
    organization,
    musik_titel,
    recht_knz,
    besetzung_knz,
    gattung,
    is_silence,
    ...data
  } = useOrgMusicWork({id: orgMusicWorkId, observe: false});

  const hasPermission = useHasPermissions({perm_write_music: true});

  return (
    <GenericTodoTask
      {...props}
      actions={(data?.id && hasPermission) ? (
        <>
          <Button
            startIcon={<Add/>}
            color="primary"
            onClick={() => setEditMusicWork({
              id: orgMusicWorkId,
              organization,
              musik_titel,
              recht_knz,
              besetzung_knz,
              gattung,
              is_silence,
            })}
          >
            Titel ergänzen
          </Button>
          {editMusicWork ? (
            <MusicWorkForm
              data={editMusicWork}
              onClose={() => setEditMusicWork(null)}
            />
          ) : null}
        </>
      ) : null}
    />
  );
});

const MusikNutzungTodoTask = React.memo(function ({metadata, actions, ...props}) {
  const {org_music_work_id: orgMusicWorkId} = metadata || {};

  const [editMusicNutzung, setEditMusicNutzung] = useState(null);

  const {
    organization,
    nutzung_art_knz,
    aufnahme_datum,
    prod_titel,
    prod_ort,
    musik_herkunft_knz,
    ...data
  } = useOrgMusicWork({id: orgMusicWorkId, observe: false});

  const hasPermission = useHasPermissions({perm_write_music: true});

  return (
    <GenericTodoTask
      {...props}
      actions={(data?.id && hasPermission) ? (
        <>
          <Button
            color="primary"
            onClick={() => setEditMusicNutzung({
              id: orgMusicWorkId,
              organization,
              nutzung_art_knz,
              aufnahme_datum,
              prod_titel,
              prod_ort,
              musik_herkunft_knz,
            })}
          >
            Musiknutzung bearbeiten
          </Button>
          {editMusicNutzung ? (
            <MusicNutzungForm
              data={editMusicNutzung}
              onClose={() => setEditMusicNutzung(null)}
            />
          ) : null}
        </>
      ) : actions}
    />
  );
});

const MusikHerkunftTodoTask = React.memo(function ({metadata, ...props}) {
  const {affected_jingle_music_works_count: affectedJingleMusicWorksCount} = metadata || {};

  const [editImportSettings, setEditImportSettings] = useState(null);

  const organization = useSelector(getSelectedOrganization);
  const {jingle_musik_herkunft_knz} = organization;

  const hasPermission = useHasPermissions({perm_write_details: true});

  return (
    <MusikNutzungTodoTask
      metadata={metadata}
      {...props}
      actions={(
        (affectedJingleMusicWorksCount > 0 && !jingle_musik_herkunft_knz && hasPermission) ? (
          <>
            <Button
              color="primary"
              startIcon={<VolumeUp/>}
              onClick={() => setEditImportSettings(organization)}
            >
              Musikherkunft Ihrer Jingle-Meldungen festlegen
            </Button>
            {editImportSettings ? (
              <JingleMusikHerkunftSettingsFormDialog
                data={editImportSettings}
                onClose={() => setEditImportSettings(null)}
                autoFocusField="jingle_musik_herkunft_knz"
              />
            ) : null}
          </>
        ) : undefined
      )}
    />
  );
});

const MusikherkunftTonaufnahmeMismatchTodoTask = React.memo(function ({metadata, actions, ...props}) {
  const {org_music_work_id: orgMusicWorkId} = metadata || {};

  const [editMusicNutzung, setEditMusicNutzung] = useState(null);

  const orgMusicWork = useOrgMusicWork({id: orgMusicWorkId, observe: false});
  const {
    organization,
    nutzung_art_knz,
    aufnahme_datum,
    prod_titel,
    prod_ort,
    musik_herkunft_knz,
    ...data
  } = orgMusicWork;

  const erschieneneTonaufnahmeId = orgMusicWork?.erschienene_tonaufnahme;

  const [editErschieneneTonaufnahme, setEditErschieneneTonaufnahme] = useState(null);

  const getErschieneneTonaufnahme = useSelector(getErschieneneTonaufnahmeGetter);
  const erschienene_tonaufnahme = getErschieneneTonaufnahme(erschieneneTonaufnahmeId);

  const hasPermission = useHasPermissions({perm_write_music: true});

  return (
    <GenericTodoTask
      {...props}
      actions={(data?.id && hasPermission) ? (
        <>
          <Button
            color="primary"
            onClick={() => setEditMusicNutzung({
              id: orgMusicWorkId,
              organization,
              nutzung_art_knz,
              aufnahme_datum,
              prod_titel,
              prod_ort,
              musik_herkunft_knz,
            })}
          >
            Musikherkunft ändern
          </Button>
          {editMusicNutzung ? (
            <MusicNutzungForm
              data={editMusicNutzung}
              onClose={() => setEditMusicNutzung(null)}
            />
          ) : null}
          <Button
            startIcon={<Album/>}
            color="primary"
            onClick={() => setEditErschieneneTonaufnahme({
              org_music_work: orgMusicWorkId,
              ...erschienene_tonaufnahme,
            })}
          >
            Erschienene Tonaufnahme bearbeiten
          </Button>
          {editErschieneneTonaufnahme ? (
            <ErschieneneTonaufnahmeForm
              data={editErschieneneTonaufnahme}
              onClose={() => setEditErschieneneTonaufnahme(null)}
            />
          ) : null}
        </>
      ) : actions}
    />
  );
});


const ErschieneneTonaufnahmeTodoTask = React.memo(function ({metadata, ...props}) {
  let {erschienene_tonaufnahme_id: erschieneneTonaufnahmeId, org_music_work_id: orgMusicWorkId} = metadata || {};

  const orgMusicWork = useOrgMusicWork({id: orgMusicWorkId, observe: false});
  if (erschieneneTonaufnahmeId === undefined) {
    erschieneneTonaufnahmeId = orgMusicWork?.erschienene_tonaufnahme;
  }

  const [editErschieneneTonaufnahme, setEditErschieneneTonaufnahme] = useState(null);

  const getErschieneneTonaufnahme = useSelector(getErschieneneTonaufnahmeGetter);
  const erschienene_tonaufnahme = getErschieneneTonaufnahme(erschieneneTonaufnahmeId);

  const hasPermission = useHasPermissions({perm_write_music: true});

  return (
    <GenericTodoTask
      {...props}
      actions={(erschienene_tonaufnahme?.id && hasPermission) ? (
        <>
          <Button
            startIcon={<Album/>}
            color="primary"
            onClick={() => setEditErschieneneTonaufnahme({
              org_music_work: orgMusicWorkId,
              ...erschienene_tonaufnahme,
            })}
          >
            Erschienene Tonaufnahme bearbeiten
          </Button>
          {editErschieneneTonaufnahme ? (
            <ErschieneneTonaufnahmeForm
              data={editErschieneneTonaufnahme}
              onClose={() => setEditErschieneneTonaufnahme(null)}
            />
          ) : null}
        </>
      ) : null}
    />
  );
});

const RueckmeldungErrorTodoTask = React.memo(function ({...props}) {
  return (
    <GenericTodoTask {...props}>
    </GenericTodoTask>
  );
});

const StationWithDeviatingDateRangeTodoTask = React.memo(function ({metadata, ...props}) {
  const {affected_stations: stationIds, station_date_ranges: stationDateRanges} = metadata || {};

  return (
    <GenericTodoTask
      {...props}
    >
      {stationIds ? (
        <Box mt={2}>
          <Typography gutterBottom component="div">
            Betroffene Programme:
            <ul>
              {stationIds?.map((stationId, i) => (
                <li key={stationId || i}>
                  Lieferung enthält Ausstrahlungen für <ProgrammeChip id={stationId}/> nur für den Teilzeitraum
                  {' '}
                  <NaturalDateRange
                    startDate={stationDateRanges[stationId][0]}
                    endDate={stationDateRanges[stationId][1]}
                  />.
                </li>
              ))}
            </ul>
          </Typography>
        </Box>
      ) : null}
    </GenericTodoTask>
  );
});


const DateWithTooManyHoursTodoTask = React.memo(function ({metadata, gemagvlxmlLieferungId, ...props}) {
  const {affected_dates: affectedDates, affected_date_stations: affectedDateStations} = metadata || {};

  return (
    <GenericTodoTask
      {...props}
    >
      {affectedDates ? (
        <Box mt={2}>
          <Typography gutterBottom component="div">
            Betroffene Tage:
            <ul>
              {affectedDates?.map((date, i) => (
                <li key={date || i}>
                  <HrefButton
                    startIcon={<Search/>}
                    color="primary"
                    href={`/dashboard/reports/${gemagvlxmlLieferungId}/entries/?date=${dateFormat(date, "yyyy-mm-dd")}`}
                  >
                    {dateFormat(date, "dddd, d. mmmm yyyy")}
                  </HrefButton>
                  &ndash;{' '}
                  {affectedDateStations[date]?.map((stationId, j) => (
                    <ProgrammeChip key={stationId || j} id={stationId}/>
                  ))}
                </li>
              ))}
            </ul>
          </Typography>
        </Box>
      ) : null}
    </GenericTodoTask>
  );
});

const TooManyEigenTodoTask = React.memo(function ({metadata, gemagvlxmlLieferungId, ...props}) {
  const {count} = metadata || {};

  const [editImportSettings, setEditImportSettings] = useState(null);

  const organization = useSelector(getSelectedOrganization);
  const {standard_musik_herkunft_knz} = organization;

  const hasPermission = useHasPermissions({perm_write_details: true});

  return (
    <GenericTodoTask
      {...props}
      actions={(
        <>
          {(count > 0 && standard_musik_herkunft_knz !== 'NEVER_EIGEN' && hasPermission) ? (
            <>
              <Button
                color="primary"
                startIcon={<ImportExport/>}
                onClick={() => setEditImportSettings(organization)}
              >
                Standard-Musikherkunft festlegen
              </Button>
              {editImportSettings ? (
                <GEMAGVL4ImportSettingsFormDialog
                  data={editImportSettings}
                  onClose={() => setEditImportSettings(null)}
                />
              ) : null}
            </>
          ) : null}
          <HrefButton
            startIcon={<Album/>}
            color="primary"
            href={`/dashboard/reports/${gemagvlxmlLieferungId}/music/?filter=musik_herkunft_knz%3DEIGEN%26nutzung_art_knz%3DSTANDARD`}
          >
            {count === 1 ? (
              <>einzige zugehörige Musikproduktion anzeigen</>
            ) : count ? (
              <>alle {count} zugehörigen Musikproduktionen anzeigen</>
            ) : (
              <>alle zugehörigen Musikproduktionen anzeigen</>
            )}
          </HrefButton>
        </>
      )}
    />
  );
});

const DateWithGapTodoTask = React.memo(function ({metadata, gemagvlxmlLieferungId, ...props}) {
  const {affected_dates: affectedDates, affected_date_stations: affectedDateStations} = metadata || {};

  return (
    <GenericTodoTask
      {...props}
    >
      {affectedDates ? (
        <Box mt={2}>
          <Typography gutterBottom component="div">
            Betroffene Tage:
            <ul>
              {affectedDates?.map((date, i) => (
                <li key={date || i}>
                  <HrefButton
                    startIcon={<Search/>}
                    color="primary"
                    href={`/dashboard/reports/${gemagvlxmlLieferungId}/entries/?date=${dateFormat(date, "yyyy-mm-dd")}`}
                  >
                    {dateFormat(date, "dddd, d. mmmm yyyy")}
                  </HrefButton>
                  &ndash;{' '}
                  keine Ausstrahlungen für
                  {' '}
                  {affectedDateStations[date]?.map((stationId, j) => (
                    <ProgrammeChip key={stationId || j} id={stationId}/>
                  ))}
                </li>
              ))}
            </ul>
          </Typography>
        </Box>
      ) : null}
    </GenericTodoTask>
  );
});

const MissingCoveredMonthsTodoTask = React.memo(function ({metadata, gemagvlxmlLieferungId, ...props}) {
  useEntityObserver({type: 'gemagvlxml_lieferung', id: gemagvlxmlLieferungId});

  const getGEMAGVLXMLLieferung = useSelector(getGEMAGVLXMLLieferungGetter);
  const {id, organization, collective_report_covered_months, stations: stationIds} = getGEMAGVLXMLLieferung(gemagvlxmlLieferungId);

  const getStation = useSelector(getStationGetter);
  const stations = stationIds?.map(getStation);

  const [coveredMonths, setCoveredMonths] = useState(null);

  return (
    <GenericTodoTask
      {...props}
      actions={(
        <>
          {id ? (
            <Button
              color="primary"
              startIcon={<DateRange/>}
              onClick={() => setCoveredMonths({id, organization, collective_report_covered_months})}
            >
              Meldezeitraum festlegen
            </Button>
          ) : null}
          {coveredMonths ? (
            <CollectiveReportCoveredMonthsFormDialog
              data={coveredMonths}
              onClose={() => setCoveredMonths(null)}
              autoFocusField="collective_report_covered_months"
            />
          ) : null}
        </>
      )}
    >
      {stations?.length > 0 ? (
        <>
          <Typography gutterBottom>
            Sie können diese Rückfrage zukünftig vermeiden, indem Sie den typischen Meldezeitraum für Sammelmeldungen in
            den Jingle-Einstellungen des jeweiligen Programms hinterlegen:
          </Typography>
          {stationIds?.map((stationId, i) => (
            <ProgrammeChip key={stationId || i} id={stationId} showEdit/>
          ))}
        </>
      ) : null}
    </GenericTodoTask>
  );
});

const MatchingTodoTask = React.memo(function ({metadata, actions, ...props}) {
  const {org_music_work_id: orgMusicWorkId, gvl_produkt_id: gvlProductId} = metadata || {};

  const {suggested_gvl_products} = useOrgMusicWork({id: orgMusicWorkId});

  const {
    id,
    gemagvlxmlLieferungId,
    hidePlaylistButton,
    hideMusicWorksButton,
    is_ausstrahlung_related: isAusstrahlungRelated,
    is_music_work_related: isMusicRelated,
    affected_music_works_count: affectedMusicWorksCount,
    affected_ausstrahlungen_count: affectedAusstrahlungenCount,
  } = props;

  const showMusicWorkButton = isMusicRelated && !hideMusicWorksButton && gemagvlxmlLieferungId;
  const showPlaylistButton = (isAusstrahlungRelated || (isMusicRelated && hideMusicWorksButton)) && !hidePlaylistButton && gemagvlxmlLieferungId && !showMusicWorkButton;

  return (
    <GenericTodoTask
      {...props}
      hideMusicWorksButton
      hidePlaylistButton
      customButtons={(
        <>
          {showPlaylistButton ? (
            <HrefButton
              startIcon={<QueueMusic/>}
              color="primary"
              href={`/dashboard/reports/${gemagvlxmlLieferungId}/entries/?todoTasks=true&todoTaskId=${id}`}
            >
              {affectedAusstrahlungenCount === 1 ? (
                <>einzige zugehörige Ausstrahlung anzeigen</>
              ) : affectedAusstrahlungenCount ? (
                <>alle {affectedAusstrahlungenCount} zugehörigen Ausstrahlungen anzeigen</>
              ) : (
                <>alle zugehörigen Ausstrahlungen anzeigen</>
              )}
            </HrefButton>
          ) : null}

          {showMusicWorkButton ? (
            <HrefButton
              startIcon={<Album/>}
              color="primary"
              href={`/dashboard/reports/${gemagvlxmlLieferungId}/music/?todoTaskId=${id}`}
            >
              {affectedMusicWorksCount === 1 ? (
                <>einzige zugehörige Musikproduktion anzeigen</>
              ) : affectedMusicWorksCount ? (
                <>alle {affectedMusicWorksCount} zugehörigen Musikproduktionen anzeigen</>
              ) : (
                <>alle zugehörigen Musikproduktionen anzeigen</>
              )}
            </HrefButton>
          ) : null}
        </>
      )}
    >
      {(orgMusicWorkId && gvlProductId) ? (
        <MusicWorkGVLProductComparisons
          orgMusicWorkId={orgMusicWorkId}
          gvlProductIdsAndScores={suggested_gvl_products?.length > 0 ? suggested_gvl_products : [
            {gvl_product_id: gvlProductId, score: 1}
          ]}
        />
      ) : suggested_gvl_products ? (
        <MusicWorkGVLProductComparisons
          orgMusicWorkId={orgMusicWorkId}
          gvlProductIdsAndScores={suggested_gvl_products}
        />
      ) : null}
    </GenericTodoTask>
  );
});



const TODO_TASK_COMPONENTS = {
  no_station_id: NoStationIdTodoTask,
  no_lieferant_id: NoLieferantIdTodoTask,
  gemagvl4syntax: GEMAGVL4AusstrahlungRelatedTodoTask,
  gemagvl4linelen: GEMAGVL4AusstrahlungRelatedTodoTask,
  overlappingtime: OverlappingTimesTodoTask,
  missingjingles: StationRelatedTodoTask,
  unexpectedjingle: StationRelatedTodoTask,
  invalidprodid: ProduktionIDTodoTask,
  missingprodid: MissingProdIdTodoTask,
  invalidurheber: MissingUrheberTodoTask,
  invalidinterpret: MissingInterpretTodoTask,
  invalidtitle: MissingTitleTodoTask,
  aufnahmedatum: MusikNutzungTodoTask,
  invalidduration: MusikNutzungTodoTask,
  missingherkunft: MusikHerkunftTodoTask,
  missingatvdosi: ErschieneneTonaufnahmeTodoTask,
  eigauferschton: MusikherkunftTonaufnahmeMismatchTodoTask,
  missinglc: ErschieneneTonaufnahmeTodoTask,
  missinglabel: ErschieneneTonaufnahmeTodoTask,
  missingvoedatum: ErschieneneTonaufnahmeTodoTask,
  erschtonaufnahme: ErschieneneTonaufnahmeTodoTask,
  xmlrueck_error: RueckmeldungErrorTodoTask,
  stationdaterange: StationWithDeviatingDateRangeTodoTask,
  too_many_hours: DateWithTooManyHoursTodoTask,
  too_many_eigen: TooManyEigenTodoTask,
  date_with_gap: DateWithGapTodoTask,
  missingcovmonths: MissingCoveredMonthsTodoTask,
  match100: MatchingTodoTask,
  match: MatchingTodoTask,
};

export const TodoTask = React.memo(function ({id, ...props}) {
  useEntityObserver({type: 'todo_task', id});

  const organization = useSelector(getSelectedOrganizationId);

  const todoTask = useSelector(getTodoTaskGetter)(id);
  const {id: todoTaskId, todo_type: todoType, isDeleted} = todoTask;

  const {deleteEntity, deletingUuids} = useEntityDeleter({
    entityType: 'todo_tasks',
    baseUrl: `/api/sendemeldung/organizations/${organization}/todo_tasks/`,
  });

  let TodoTaskComponent = TODO_TASK_COMPONENTS[todoType] || GenericTodoTask;

  return (
    todoTaskId ? (
      <TodoTaskComponent
        {...todoTask}
        deleteTask={organization ? () => deleteEntity(todoTaskId) : undefined}
        isDeletingTask={deletingUuids?.has(todoTaskId)}
        {...props}
      />
    ) : isDeleted ? (
      <GenericTodoTask
        message={{
          title: "Erledigt",
          text: "Diese Aufgabe wurde bereits erledigt oder gelöscht.",
          severity: 'info',
        }}
      >
      </GenericTodoTask>
    ) : (
      <Skeleton>
        <GenericTodoTask
          message={{
            title: "Test",
            text: "Diese Aufgabe wird gerade geladen.",
            severity: 'info',
          }}
        >
        </GenericTodoTask>
      </Skeleton>
    )
  );
});
