import React from "react";

export default function SimpleDuration({value}) {
  return (
    <>
      {!value ? (
        <>0 Sekunden</>
      ) : value === 1 ? (
        <>1 Sekunde</>
      ) : value < 60 ? (
        <>{value.toFixed(0)} Sekunden</>
      ) : value === 60 ? (
        <>1 Minute</>
      ) : value < 3600 ? (
        <>{(value / 60).toFixed(0)} Minuten</>
      ) : value === 3600 ? (
        <>1 Stunde</>
      ) : (
        <>{(value / 3600).toFixed(1).replace('.', ',')} Stunden</>
      )}
    </>
  );
}
